<template>
  <div class="main">
    <TableMain
      tableTitle="Empleados"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Crear Empleado"
      :disableMainButton="disableNewEmployees"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="[
        '#',
        'Cédula',
        'Nombre',
        'Rol',
        'Área de Trabajo',
        'Tipo de Contrato'
      ]"
      :data="employees"
      :editOption="true"
      :deleteOption="true"
      :transferOption="true"
      :printOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
      @transferAction="transferEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

import { prettyID, titleCase } from "@/modules/tools";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Empleados / Perfiles / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    editEvent(_id) {
      this.$router.push({
        name: "Employee Profile Edit",
        params: {
          _id: _id
        }
      });
    },
    transferEvent(_id) {
      const store = new Storage();
      store.getData("branch").then(branchData => {
        branchData.forEach(branch => {
          store.getData("city", branch.city).then(async cityData => {
            this.branchOptions[cityData.name] = {};
            this.branchOptions[cityData.name][branch._id] = branch.description;
            const { value: branchId } = await Swal.fire({
              title: "Transferir a Sede",
              input: "select",
              inputOptions: this.branchOptions,
              inputPlaceholder: "Selecciona una sede",
              showCancelButton: true,
              confirmButtonText: `Transferir`,
              cancelButtonText: "Cancelar"
            });

            if (branchId) {
              Swal.fire({
                icon: "warning",
                title: "Transfiriendo empleado",
                html:
                  "Por favor espere mientras transferimos al empleado</b> " +
                  '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                stopKeydownPropagation: true
              });
              const data = {
                _id: _id,
                branch: branchId
              };
              store
                .updateData("employee", [data], true, "optima")
                .then(() => {
                  Swal.fire({
                    icon: "success",
                    title: "Empleado transferido a otra sede",
                    text: "Todos los datos han sido sincronizados"
                  }).then(() => {
                    this.$router.go();
                  });
                })
                .catch(error => {
                  console.log(error);
                  Swal.fire({
                    icon: "error",
                    title: "Error al transferir al empleado",
                    text:
                      "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
                  }).then(() => {
                    this.$router.go();
                  });
                });
            }
          });
        });
      });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("employee", _id).then(employee => {
        Swal.fire({
          title: "¿Retirar Empleado?",
          text: "Se retirará al empleado: " + employee.name,
          showCancelButton: true,
          confirmButtonText: `Retirar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Retirando empleado",
              html:
                "Por favor espere mientras se retira al empleado</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            store.deleteData("optima", "employee", _id).then(() => {
              Swal.fire({
                icon: "success",
                title: "Empleado Retirado",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                this.$router.go();
              });
            });
          }
        });
      });
    },
    getWorkArea(workId, position) {
      this.workAreas.forEach(area => {
        if (area._id === workId) {
          this.employees[position][4] = area.name;
        }
      });
    }
  },
  async created() {
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const employeeData = await store.getData("employee");
    const totalEmployee = employeeData.length;
    const limitEmployee = planData.restrictions.employees;

    if (totalEmployee >= limitEmployee) {
      this.disableNewEmployees = "true";
      Swal.fire({
        title: "No es posible registrar nuevos empleados",
        icon: "warning",
        text:
          "La susbscripción que posee tiene un límite de " +
          limitEmployee +
          " empleados, actualmente están registrados " +
          totalEmployee +
          ". Para registrar nuevos empleados por favor cambie de plan.",
        showCancelButton: true,
        confirmButtonText: `Cambiar Plan`,
        cancelButtonText: "Continuar",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: "Subscription" });
        }
      });
    }

    let aux = 0;
    store.getData("workArea").then(workData => {
      this.workAreas = workData;
      store.getData("employee").then(employeesData => {
        employeesData.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
        employeesData.forEach(employee => {
          if (employee.branch === this.$route.params._id) {
            prettyID(employee.ID).then(ID => {
              this.employees.push([
                employee._id,
                ID,
                employee.name,
                "Empleado",
                null,
                null
              ]);
              this.getWorkArea(employee.workArea, aux);
              try {
                this.employees[aux][5] = titleCase(
                  employee.typeOfContract.split("_").join(" ")
                );
              } catch (error) {
                this.employees[aux][5] = null;
              }
              aux++;
            });
          }
        });
      });
    });
  },
  data() {
    return {
      employees: [],
      branchOptions: {},
      workAreas: [],
      disableNewEmployees: ""
    };
  },
  name: "Employee Profile Branch"
};
</script>
